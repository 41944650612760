import React from 'react';
import Layout from '../../components/layout';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import './picks.scss';

const Page = () => (
  <Layout>
    <Helmet>
      <body page-name="page-picks" />
    </Helmet>
    <main>
      <h1>
        <Link to="/picks">picks</Link> / podcasts
      </h1>

      <p>Interesting shows that go into my ears every week</p>

      <hr />

      <dl>
        {pods.map(([title, url, desc]) => (
          <React.Fragment key={title}>
            <h3>{title}</h3>
            <p>
              <a href={url} target="_blank">
                <code>{url}</code>
              </a>
            </p>
            {desc ? <p>{desc}</p> : null}
          </React.Fragment>
        ))}
      </dl>
    </main>
  </Layout>
);

//prettier-ignore
const pods = [
  [
    'Accidental Tech Podcast',
    'http://atp.fm',
  ],
  [
    'The Changelog',
    'https://changelog.com/podcast',
    'Conversations with the hackers, leaders, and innovators of software development'
  ],
  [
    'Epicenter',
    'https://epicenter.tv/',
    'Weekly podcast on blockchain, Ethereum, Bitcoin and distributed technologies'
  ],
  [
    'Shop Talk Show',
    'https://shoptalkshow.com',
    'An internet radio show about the internet'
  ],
  [
    'Full Stack Radio',
    'http://www.fullstackradio.com/',
  ],
  [
    'Software Engineering Daily',
    'https://softwareengineeringdaily.com/',
  ],
  [
    'Into the Ether',
    'https://podcast.ethhub.io/'
  ],
  [
    'a16z Podcast',
    'https://a16z.com/podcasts',
    'Tech and culture trends, news, and the future'
  ],
  [
    'The Big Web Show',
    'http://5by5.tv/bigwebshow',
    'Web publishing, art direction, content strategy, typography, web technology, and more'
  ],
  [
    'Front End Happy Hour',
    'https://frontendhappyhour.com/',
  ],
  [
    'Modern Web Podcast',
    'https://www.thisdot.co/modern-web',

  ]
];

export default Page;
